import { Controller } from "@hotwired/stimulus"

const later = (callback) => requestAnimationFrame(() => requestAnimationFrame(callback))

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = ["menu", "toggleable", "clickToHide"]
  static values = {
    active: { type: Boolean, default: false }
  }


  toggle() {
    this.activeValue = !this.activeValue
  }

  hideOnMenuClick(e) {
    if (this.clickToHideTargets.includes(e.target)) {
      this.hide()
    }
  }

  hide() {
    this.activeValue = false
  }


  activeValueChanged() {
    this.updateMenuVisibility()
    this.updateToggleables()
  }

  updateMenuVisibility() {
    if (this.activeValue) {
      this.menuTarget.classList.remove("hidden")
    } else {
      this.menuTarget.classList.add("hidden")
    }
  }

  updateToggleables() {
    this.toggleableTargets.forEach((toggleable) => {
      const { classList } = toggleable
      const { menuActiveClass, menuActiveLateClass, menuInactiveClass, menuInactiveLateClass } = toggleable.dataset
      const activeClasses = this.parseClass(menuActiveClass)
      const inactiveClasses = this.parseClass(menuInactiveClass)
      const activeLateClasses = this.parseClass(menuActiveLateClass)
      const inactiveLateClasses = this.parseClass(menuInactiveLateClass)

      if (this.activeValue) {
        classList.add(...activeClasses)
        classList.remove(...activeLateClasses)
        classList.remove(...inactiveClasses)

        if (activeLateClasses.length) {
          later(() => {
            classList.remove(...activeClasses)
            classList.add(...activeLateClasses)
          })
        }
      } else {
        classList.add(...inactiveClasses)
        classList.remove(...inactiveLateClasses)
        classList.remove(...activeClasses)

        if (inactiveLateClasses.length) {
          later(() => {
            classList.remove(...inactiveClasses)
            classList.add(...inactiveLateClasses)
          })
        }
      }
    })
  }


  parseClass(classString) {
    return classString?.split(/\s+/) || []
  }
}
