import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-upload"
export default class extends Controller {
  static targets = ["spinnerTemplate", "spinner", "statusUploadProgress", "statusUploadFinished", "progressText", "input"]

  connect() {
    this.inputTarget.addEventListener("direct-upload:initialize", this.onStart)
    this.inputTarget.addEventListener("direct-upload:progress", this.onProgress)
    this.inputTarget.addEventListener("direct-upload:end", this.onEnd)
  }

  disconnect() {
    this.inputTarget.removeEventListener("direct-upload:initialize", this.onStart)
    this.inputTarget.removeEventListener("direct-upload:progress", this.onProgress)
    this.inputTarget.removeEventListener("direct-upload:end", this.onEnd)
  }


  onStart = (e) => {
    this.spinnerTarget.innerHTML = this.spinnerTemplateTarget.innerHTML
    this.statusUploadProgressTarget.classList.remove("hidden")
    this.statusUploadFinishedTarget.classList.add("hidden")
  }

  onProgress = (e) => {
    const { progress } = e.detail

    this.progressTextTarget.innerText = `${Math.round(progress)} %`
  }

  onEnd = (e) => {
    this.statusUploadProgressTarget.classList.add("hidden")
    this.statusUploadFinishedTarget.classList.remove("hidden")
  }

}
