import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

const nextFrame = () => {
  return new Promise((resolve, reject) => {
    requestAnimationFrame(() => requestAnimationFrame(resolve))
  })
}

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static values = {
    url: String,
    page: { type: Number, default: 2 },
    offset: { type: Number, default: 50 },
    loading: Boolean,
    active: Boolean,
  }

  static targets = ["scroll", "spinner", "noMoreItems"]

  static classes = ["spinnerActive", "spinnerInactive"]

  connect() {
    this.activeValue = true

    document.addEventListener("scroll", this.checkIfNeedsLoading, { passive: true })
    window.addEventListener("resize", this.checkIfNeedsLoading, { passive: true })

    requestAnimationFrame(() => requestAnimationFrame(() => this.checkIfNeedsLoading()))
  }

  disconnect() {
    this.removeListener()
  }

  removeListener() {
    document.removeEventListener("scroll", this.checkIfNeedsLoading, { passive: true })
    window.removeEventListener("resize", this.checkIfNeedsLoading, { passive: true })
  }

  loadingValueChanged() {
    this.updateSpinner()
  }

  checkIfNeedsLoading = (e) => {
    if (this.loadingValue || this.hasNoMoreItemsTarget) { return }

    if (this.scrollTargetIsVisible()) {
      this.loadNextPage()
    }
  }

  scrollTargetIsVisible() {
    const { offsetTop } = this.scrollTarget
    const doc = document.documentElement;
    const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    return offsetTop - this.offsetValue <= scrollTop + doc.clientHeight
  }

  loadNextPage() {
    const url = this.urlValue.replace("PAGE", this.pageValue++)

    // loading Value needs to be set in synchronous function
    // Otherwise, race-conditions occur

    this.loadingValue = true
    this.loadPageFromUrl(url)
  }

  async loadPageFromUrl(url) {
    this.loadingValue = true

    await get(url)
    await nextFrame()

    this.loadingValue = false
    this.checkIfNeedsLoading()
  }

  updateSpinner() {
    if (this.loadingValue) {
      this.spinnerTarget.classList.remove(...this.spinnerInactiveClasses)
      this.spinnerTarget.classList.add(...this.spinnerActiveClasses)
    } else {
      this.spinnerTarget.classList.remove(...this.spinnerActiveClasses)
      this.spinnerTarget.classList.add(...this.spinnerInactiveClasses)
    }
  }

  noMoreItemsTargetConnected(target) {
    this.activeValue = false
  }
}
