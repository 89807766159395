import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggleable"
export default class extends Controller {
  static values = {
    active: { type: Boolean, default: false }
  }

  static targets = ["toggleable"]

  connect() {
  }

  toggle() {
    this.activeValue = !this.activeValue
  }

  activeValueChanged() {
    this.toggleableTargets.forEach((toggleable) => this.updateToggleable(toggleable))
  }

  updateToggleable(toggleable) {
    const { dataset, classList } = toggleable
    const activeClasses = this.parseClass(dataset.toggleableActiveClass)
    const inactiveClasses = this.parseClass(dataset.toggleableInactiveClass)

    if (this.activeValue) {
      classList.remove(...inactiveClasses)
      classList.add(...activeClasses)
    } else {
      classList.remove(...activeClasses)
      classList.add(...inactiveClasses)
    }
  }

  parseClass(classString) {
    return classString?.split(/\s+/) || []
  }

}
