import { getHexColor } from "./color_utils"
import Trix from "trix"

const PRIMARY_COLOR = "#aa2126"

Trix.config.textAttributes.highlightColor = {
  style: { color: PRIMARY_COLOR },
  inheritable: true,
  parser: (element) => {
    parsedColor = getHexColor(element.style.color)

    return parsedColor.toLowerCase() == PRIMARY_COLOR
  }
}

document.addEventListener("trix-initialize", (e) => {
  const editor = e.target
  const { toolbarElement } = editor
  const textTools = toolbarElement.querySelector(".trix-button-group--text-tools")

  const highlightColorButtonHTML = `<button type="button" class="trix-button trix-button--icon trix-button--icon-highlight-color" data-trix-attribute="highlightColor" title="Highlight Color" tabindex="-1">Highlight Color</button>`

  textTools.insertAdjacentHTML("beforeend", highlightColorButtonHTML)
})