import { Controller } from "@hotwired/stimulus"

function identifyTransitionEvent(){
  const el = document.createElement('fakeelement');
  const transitions = {
    'WebkitTransition' :'webkitTransitionEnd',
    'MozTransition'    :'transitionend',
    'MSTransition'     :'msTransitionEnd',
    'OTransition'      :'oTransitionEnd',
    'transition'       :'transitionEnd'
  }

  for (let t in transitions) {
    if(el.style[t] !== undefined ){
      return transitions[t];
    }
  }
}

class Animator {
  get currentClassList() { return this.currentSlide.classList }
  get nextClassList() { return this.nextSlide.classList }

  constructor(currentSlide, nextSlide, forward) {
    this.currentSlide = currentSlide
    this.nextSlide = nextSlide
    this.forward = forward
  }

  // Public Interface
  animate() {
    this.starting()
    this.nextTick(() => {
      this._installFinishTimeout()
      this.start()
    })
  }

  onFinish(callback) {
    this.onFinishCallback = callback
  }

  // Animator interface

  starting() {
    // console.log("Starting...")
  }

  start() {
    // console.log("Starting!")
  }

  finishing() {
    // console.log("Finishing...")
  }

  finish() {
    // console.log("Finished!")
  }

  duration() {
    return 0;
  }

  // Private
  _installFinishTimeout() {
    setTimeout(this._finishHandler.bind(this), this.duration())
  }

  _finishHandler() {
    this.finishing()

    this.nextTick(() => {
      this.finish()

      this.nextTick(() => this._applyOnFinishCallback())
    })
  }

  _applyOnFinishCallback() {
    if (this.onFinishCallback) {
      this.onFinishCallback(this)
    }
  }

  nextTick(callback) {
    // requestAnimationFrame twice to ensure we *really* get an new frame *derp*
    requestAnimationFrame(() => requestAnimationFrame(callback))
  }
}

class TransitionAnimation {
  constructor() {
    this.duration_millis = 500

    this.current_base_classes = []
    this.current_from_classes = []
    this.current_to_classes = []

    this.next_base_classes = []
    this.next_from_classes = []
    this.next_to_classes = []
  }

  duration(duration) { this.duration_millis = duration }
  current_base(...classes) { this.current_base_classes = classes }
  current_from(...classes) { this.current_from_classes = classes }
  current_to(...classes)   { this.current_to_classes   = classes }

  next_base(...classes) { this.next_base_classes = classes }
  next_from(...classes) { this.next_from_classes = classes }
  next_to(...classes)   { this.next_to_classes   = classes }
}

class TransitionAnimator extends Animator {
  duration() {
    return this.animation.duration_millis
  }

  starting() {
    this.animation = this.buildAnimation()

    this.nextClassList.remove("hidden")

    this.nextClassList.add(...this.animation.next_from_classes, ...this.animation.next_base_classes)
    this.currentClassList.add(...this.animation.current_from_classes, ...this.animation.current_base_classes)
  }

  start() {
    this.nextClassList.remove(...this.animation.next_from_classes)
    this.nextClassList.add(...this.animation.next_to_classes)

    this.currentClassList.remove(...this.animation.current_from_classes)
    this.currentClassList.add(...this.animation.current_to_classes)
  }

  finish() {
    this.nextClassList.remove(...this.animation.next_to_classes, ...this.animation.next_base_classes)
    this.currentClassList.remove(...this.animation.current_to_classes, ...this.animation.current_base_classes)

    this.currentClassList.add("hidden")
  }
}

class CrossfadeAnimator extends TransitionAnimator {
  buildAnimation() {
    const anim = new TransitionAnimation()

    anim.duration = 500
    anim.next_base("transition", "duration-1000", "z-10")
    anim.next_from("opacity-0")
    anim.next_to("opacity-100")

    return anim
  }
}

class SlideAnimator extends TransitionAnimator {
  buildAnimation() {
    const anim = new TransitionAnimation()

    anim.duration = 500
    anim.current_base("transform", "transition-transform", "duration-500")
    anim.next_base("transform", "transition-transform", "duration-500")

    if (this.forward){
      anim.current_from("translate-x-0")
      anim.current_to("-translate-x-full")

      anim.next_from("translate-x-full")
      anim.next_to("translate-x-0")
    } else {
      anim.current_from("translate-x-0")
      anim.current_to("translate-x-full")

      anim.next_from("-translate-x-full")
      anim.next_to("translate-x-0")
    }

    return anim
  }
}

const ANIMATORS = {
  crossfade: CrossfadeAnimator,
  slide: SlideAnimator
}

// Connects to data-controller="slider"
export default class extends Controller {
  static values = {
    interval: Number,
    autoplay: Boolean,
    wrap: { type: Boolean, default: true },
    pauseable: Boolean,
    keyboard: Boolean,
    autoheight: { type: Boolean, default: true },
    animation: { type: String, default: "slide" },

    // Internal state
    activeSlideIndex: Number,
    isAnimating: Boolean,
  }
  static targets = ["slide", "indicator", "track"]
  static classes = ["activeSlide", "inactiveSlide"]

  initialize() {
    this._timerTick = this._timerTick.bind(this)
    this._finishAnimation = this._finishAnimation.bind(this)
  }

  connect() {
    this._activateFirstSlide()

    this.startAutoplayTimer()
  }

  next() {
    const nextSlide = this._findNextSlide()

    if (nextSlide) {
      this._animateTo(nextSlide)
    }
  }

  prev() {
    const prevSlide = this._findPrevSlide()

    if (prevSlide) {
      this._animateTo(prevSlide, false)
    }
  }

  animateToSlide(e) {
    let slideIndex;

    if (typeof e == "number") {
      slideIndex = e
    } else {
      slideIndex = this._getSlideIndex(e.currentTarget)
    }

    const slide = this._findSlideWithIndex(slideIndex)
    const forward = this.activeSlideIndexValue <= slideIndex

    this._animateTo(slide, forward)
  }

  pauseAutoplayTimer() {
    // console.log("Pausing not yet implemented")
  }

  startAutoplayTimer() {
    if (this.autoplayValue && this.slideTargets.length > 1) {
      this._startTimer()
    }
  }

  activeSlideIndexValueChanged() {
    this._updateTrackHeightForActiveSlide()
  }

  updateHeight() {
    this._updateTrackHeightForActiveSlide()
  }

  // =============
  // = Callbacks =
  // =============

  finishAnimation() {
    this._finishAnimation()
    this.startAutoplayTimer()
  }

  // ====================
  // = Animating Slides =
  // ====================

  _animateTo(slide, forward = true) {
    const currentSlide = this._findActiveSlide()
    const nextSlide = slide

    if (this.isAnimatingValue) {
      this.delayedAnimation = { slide, forward }
      this._activateIndicatorForSlide(slide)

      return
    }

    if (currentSlide == nextSlide) {
      this.startAutoplayTimer()
      return
    }

    this.isAnimatingValue = true
    this._stopTimer()

    const animatorClass = this._getAnimatorClass()

    this.animator = new animatorClass(currentSlide, nextSlide, forward)
    this.animator.onFinish(this._finishAnimation)
    this.animator.animate(forward)

    this._activateIndicatorForSlide(nextSlide)

  }

  _finishAnimation(animator) {
    const slide = animator.nextSlide

    this._activateSlide(slide)
    this.isAnimatingValue = false

    if (this.delayedAnimation) {
      this._animateTo(this.delayedAnimation.slide, this.delayedAnimation.forward)
      this.delayedAnimation = null
    } else {
      this.startAutoplayTimer()
    }
  }

  _getAnimatorClass() {
    return ANIMATORS[this.animationValue]
  }

  // ==================
  // = Timer Handling =
  // ==================

  _timerTick() {
    this.timerTimeout = null

    this.next()
  }

  _hasActiveTimer() {
    return this.timerTimeout
  }

  _startTimer() {
    if (this._hasActiveTimer()) { this._stopTimer() }

    const delay = this._getDelayForActiveSlide()

    this.timerTimeout = setTimeout(this._timerTick, delay)
  }

  _stopTimer() {
    if (this._hasActiveTimer()) {
      clearTimeout(this.timerTimeout)

      this.timerTimeout = null
    }
  }

  _getDelayForActiveSlide() {
    const activeSlide = this._findActiveSlide()
    const slideDelay = activeSlide.dataset.sliderSlideInterval

    if (slideDelay) {
      return parseInt(slideDelay)
    } else {
      return this.intervalValue
    }
  }

  // =====================
  // = Activating Slides =
  // =====================

  _activateFirstSlide() {
    this._activateSlide(this.slideTargets[0])
  }

  _activateSlide(slide) {
    this.activeSlideIndexValue = this._getSlideIndex(slide)

    this._activateSlideWithIndex(this.activeSlideIndexValue)
    this._activateIndicatorWithIndex(this.activeSlideIndexValue)

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this._updateTrackHeightForActiveSlide()
      })
    })

  }

  _activateSlideWithIndex(index) {
    this.slideTargets.forEach((slideTarget) => {
      const isActive = index == this._getSlideIndex(slideTarget)

      slideTarget.classList.toggle(this.activeSlideClass, isActive)
      slideTarget.classList.toggle(this.inactiveSlideClass, !isActive)
    })
  }

  _activateIndicatorForSlide(slide) {
    const slideIndex = this._getSlideIndex(slide)

    this._activateIndicatorWithIndex(slideIndex)
  }

  _activateIndicatorWithIndex(index) {
    this.indicatorTargets.forEach((indicatorTarget) => {
      const isActive = index == this._getSlideIndex(indicatorTarget)

      const activeClasses = this._parseClasses(this.indicatorTarget.dataset.sliderIndicatorActiveClass)
      const inactiveClasses = this._parseClasses(this.indicatorTarget.dataset.sliderIndicatorInactiveClass)

      if (isActive) {
        indicatorTarget.classList.remove(...inactiveClasses)
        indicatorTarget.classList.add(...activeClasses)
      } else {
        indicatorTarget.classList.remove(...activeClasses)
        indicatorTarget.classList.add(...inactiveClasses)
      }
    })
  }

  // ===============
  // = Auto Height =
  // ===============

  _updateTrackHeightForActiveSlide() {
    const slide = this._findActiveSlide()
    const height = slide.clientHeight

    this.trackTarget.style.height = `${height}px`
  }

  // ====================
  // = Accessing Slides =
  // ====================

  _getSlideIndex(indexable) {
    const index = indexable.dataset.sliderSlideIndex

    return parseInt(index)
  }

  _getSlideCount() {
    return this.slideTargets.length
  }

  _findActiveSlide() {
    return this._findSlideWithIndex(this.activeSlideIndexValue)
  }

  _findNextSlide() {
    const slideCount = this._getSlideCount()
    const currentSlideIndex = this.activeSlideIndexValue

    let nextIndex = currentSlideIndex + 1

    if (nextIndex >= slideCount) {
      if (this.wrapValue) {
        nextIndex = 0
      } else {
        nextIndex = slideCount - 1
      }
    }

    return this._findSlideWithIndex(nextIndex)
  }

  _findPrevSlide() {
    const slideCount = this._getSlideCount()
    const currentSlideIndex = this.activeSlideIndexValue

    let prevIndex = currentSlideIndex - 1

    if (prevIndex < 0) {
      if (this.wrapValue) {
        prevIndex = slideCount - 1
      } else {
        prevIndex = 0
      }
    }

    return this._findSlideWithIndex(prevIndex)
  }

  _findSlideWithIndex(index) {
    return this.slideTargets[index]
  }

  // =========
  // = Utils =
  // =========

  _parseClasses(classString) {
    if (classString) {
      return classString.split(/\s+/)
    } else {
      return []
    }
  }
}
