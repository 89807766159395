// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"


ActiveStorage.start()

import "./vendor/font_awesome"
import "./vendor/iconizer"
import "./vendor/trix"

import "./controllers"

import "trix"
import "@rails/actiontext"