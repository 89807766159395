import Trix from "trix"

const getRGBValues = (color) => {
  const matcher = /rgba?\((\d+),\s*(\d+),\s*(\d+)/i

  let r, g, b
  let match = color.match(matcher)

  if (match) {
    [r, g, b] = match.slice(-3)

    return [r, g, b].map(Number)
  } else {
    const div = document.createElement("div")
    div.style.color = color

    document.body.appendChild(div)

    const computedStyle = window.getComputedStyle(div)
    const colorStr = computedStyle["color"].toString()

    div.remove()

    return getRGBValues(colorStr)
  }
}


const numberToHex = (number) => `0${number.toString(16)}`.slice(-2).toUpperCase()

export function getHexColor(color) {
  if (!color) { return "" }
  if (/^#/.test(color)) { return color }

  rgbValues = getRGBValues(color)
  hexValues = rgbValues.map(numberToHex)

  return "#" + hexValues.join("")
}

export function createColorParser(){
  return (element) => {
    while (element && element.tagName != "TRIX-EDITOR") {
      const styleColor = element.style[this.styleProperty]
      if (styleColor) {
        const color = getHexColor(styleColor)

        if (color) {
          return color
        }
      }

      element = element.parentElement
    }
  }
}