import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="map-editor"
export default class extends Controller {

  static targets = ["markerTemplate", "marker", "latInput", "lngInput"]

  get markersContainerTarget() { return this.element.querySelector("[data-map-target=markersContainer]")}

  get latValue() { return this.latInputTarget.value }
  set latValue(value) { this.latInputTarget.value = value }

  get lngValue() { return this.lngInputTarget.value }
  set lngValue(value) { this.lngInputTarget.value = value }

  connect() {
    this.updateMarkerFromInputs()
    if (this.latValue && this.lngValue) {
      this.updateMarker(this.getInputCoordinates())
    }
  }

  handleClick(e) {
    const { leafletEvent } = e.detail

    const { latlng, originalEvent } = leafletEvent
    const { altKey } = originalEvent

    if (altKey) {
      this.updateMarker(latlng)
      this.updateInputs(latlng)
    }
  }

  handleMarkerDrag(e) {
    const { latlng } = e.detail

    this.updateInputs(latlng)
  }

  handleInputChanged(e) {
    this.updateMarkerFromInputs()
  }

  updateMarker(latlng) {
    const html = (latlng) ? this.buildMarker(latlng) : ""

    this.markersContainerTarget.innerHTML = html
  }

  updateInputs(latlng) {
    this.latValue = latlng.lat
    this.lngValue = latlng.lng
  }

  updateMarkerFromInputs() {
    const latlng = this.getInputCoordinates()

    this.updateMarker(latlng)
  }

  getInputCoordinates() {
    const { latValue, lngValue } = this

    if (latValue && lngValue){
      return { lat: latValue, lng: lngValue }
    } else {
      return null
    }
  }

  buildMarker(coordinates) {
    const { lat, lng } = coordinates

    const template = this.markerTemplateTarget.innerHTML
    const marker = template.replace(/LAT/g, lat).replace(/LNG/g, lng)

    return marker
  }
}
