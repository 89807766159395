import Sortable from 'sortablejs'
import { Controller } from "@hotwired/stimulus"
import { put } from '@rails/request.js'

// Connects to data-controller="sortable"
export default class extends Controller {

  static values = {
    enableHandles: { type: Boolean, default: false },
    handleSelector: { type: String, default: "*[data-sortable-target~=handle]" },
    sort: { type: Boolean, default: true },
    group: { type: String, default: "" },
    enablePull: { type: Boolean, default: false },
    disablePut: { type: Boolean, default: false },
    method: { type: String, default: "ajax" },
    onSortedEventName: { type: String, default: "sortable:sorted" },
    animation: { type: Number, default: 150 },
  }

  static targets = ["handle"]

  get sortableTarget() { return this.element }

  connect() {
    this.sortable = new Sortable(this.sortableTarget, this._buildSortableOptions())
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy()
      this.sortable = null
    }
  }

  _handleSorted(e) {
    switch (this.methodValue) {
      case "ajax": this._handleAjaxSort(e); break;
      case "event": this._handleEventSort(e); break;
    }
  }

  _handleAjaxSort(e) {
    const { item, newIndex } = e
    const url = item.dataset.sortableUrl.replace("INDEX", newIndex)

    put(url)
  }

  _handleEventSort(e) {
    const { item, newIndex } = e
    const event = new CustomEvent(this.onSortedEventNameValue, { detail: { item, newIndex } })

    this.element.dispatchEvent(event)
  }

  _buildSortableOptions() {
    let groupOptions = null

    if (this.groupValue) {
      groupOptions = {}
      groupOptions.name = this.groupValue

      if (this.enablePullValue) {
        groupOptions.pull = "clone"
      }

      if (this.disablePutValue) {
        groupOptions.put = false
      }
    }


    const options = {
      sort: this.sortValue,
      forceFallback: true,
      group: groupOptions,
      onEnd: this._handleSorted.bind(this)
    }

    if (this.animationValue > 0) {
      options.animation = this.animationValue
    }

    if (this.enableHandlesValue) {
      options.handle = this.handleSelectorValue
    }

    return options
  }
}
