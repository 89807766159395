import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-sortable"
export default class extends Controller {
  static targets = ["position"]

  connect() {
    this.update()
  }

  update() {
    this.positionTargets.forEach((el, idx) => {
      el.value = idx
    })
  }
}
