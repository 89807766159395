import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-submit"
export default class extends Controller {
  static targets = ["submittable"]
  static values = {
    debounce: { Number, default: 200 },
  }

  get submitTarget() {
    if (this.hasSubmittableTarget) {
      return this.submittableTarget
    } else {
      return this.element
    }
  }

  connect() {}

  submit() {
    if (this.debounceValue > 0){
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => this._requestSubmit(), this.debounceValue)
    } else {
      this._requestSubmit()
    }
  }

  submitImmediately() {
    clearTimeout(this.debounceTimeout)

    this._requestSubmit()
  }

  _requestSubmit() {
    this.submitTarget.requestSubmit()
  }
}
