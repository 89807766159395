import { Controller } from "@hotwired/stimulus"
import L from "../vendor/leaflet"

// Connects to data-controller="map"
export default class extends Controller {
  static targets = ["container", "marker"]

  static values = {
    iconUrl: String,
    iconRetinaUrl: String,
    shadowUrl: String,

    fitBounds: Boolean,
    initialZoom: Number,
    initialCoordinates: Object
  }

  connect() {
    this.setupMarkerIcon()
    this.setupCloseButtonListener()

    this.createMap()
    this.createScaleControl()
    this.createTileLayer()
    this.createGeoJSONLayer()

    this.updateMapData()
  }

  disconnect() {
    if (this.map){
      this.map.remove()
      this.map = null
    }

    this.destroyCloseButtonListener()
  }

  markerTargetConnected() {
    if (this.map) {
      this.updateMapData()
    }
  }

  markerTargetDisconnected() {
    if (this.map) {
      this.updateMapData()
    }
  }


  setupMarkerIcon() {
    this.leafletMarkerIcon = new L.icon({
      iconUrl: this.iconUrlValue,
      iconRetinaUrl: this.iconRetinaUrlValue,
      shadowUrl: this.shadowUrlValue,

      iconSize: [25,41],
      iconAnchor: [12,41],
      popupAnchor: [1,-34],
      tooltipAnchor: [16,-28],
      shadowSize: [41,41],
    })
  }

  setupCloseButtonListener() {
    this.element.addEventListener("click", this.closeButtonListener)
  }

  destroyCloseButtonListener() {
    this.element.removeEventListener("click", this.closeButtonListener)
  }

  createMap() {
    const map = L.map(this.containerTarget)
      .setView(this.initialCoordinatesValue, this.initialZoomValue);

    map.on("click", (e) => this.dispatchEvent("map:click", { leafletEvent: e }))

    this.map = map
  }

  createScaleControl() {
    const control = L.control.scale({imperial: false, metric: true})

    control.addTo(this.map)
  }

  createTileLayer() {
    const layer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://openstreetmap.org/copyright" target="_blank">OpenStreetMap contributors</a>'
    })

    layer.addTo(this.map);
  }

  createGeoJSONLayer() {
    const options = {
      pointToLayer: (geoJSON, latlng) => this.buildMarker(geoJSON, latlng)
    }

    const markerLayer = L.geoJSON([], options)
      .bindPopup((layer) => layer.feature.properties.infoWindowContent)
      .addTo(this.map)

    this.markerLayer = markerLayer
  }

  updateMapData() {
    this.updateMarkers()
    this.updateBounds()
  }

  updateMarkers() {
    const markers = this.getMarkersGeoJSON()

    this.markerLayer.eachLayer((layer) => layer.remove())
    this.markerLayer.addData(markers)
  }

  updateBounds() {
    if (!this.fitBoundsValue) { return }

    const bounds = this.getMarkersBounds()
    const fitOptions = {
      padding: L.point(50, 50)
    }

    if (bounds) {
      this.map.fitBounds(bounds, fitOptions)
    }
  }

  buildMarker(geoJSON, latlng)  {
    const options = { icon: this.leafletMarkerIcon, draggable: geoJSON.properties.draggable }
    const marker = L.marker(latlng, options)

    marker.on("drag", (e) => this.dispatchEvent("map:marker:drag", { markerProperties: geoJSON.properties, leafletEvent: e, latlng: e.target.getLatLng() }))
    marker.on("dragend", (e) => this.dispatchEvent("map:marker:dragend", { markerProperties: geoJSON.properties, leafletEvent: e, latlng: e.target.getLatLng() }))

    return marker
  }

  closeButtonListener = (e) => {
    if (e.target) {
      const link = e.target.closest("a")

      if (link?.classList?.contains("leaflet-popup-close-button")) {
        e.preventDefault()
      }
    }
  }

  getMarkersGeoJSON() {
    return this.markerTargets.map((target) => {
      const { dataset } = target

      const coordinates = JSON.parse(dataset.mapMarkerCoordinates)
      const infoWindowContent = dataset.mapMarkerInfoWindowContent
      const draggable = dataset.mapMarkerDraggable == "true"

      return {
        type: "Feature",
        properties: {
          infoWindowContent, draggable
        },
        geometry: {
          type: "Point",
          coordinates: [coordinates.lng, coordinates.lat]
        }
      }
    })
  }

  getMarkersBounds() {
    const latLngs = this.markerTargets.map((target) => {
      const { dataset } = target
      const coordinates = JSON.parse(dataset.mapMarkerCoordinates)

      return L.latLng(coordinates.lat, coordinates.lng)
    })

    if (latLngs.length > 2) {
      return L.latLngBounds(latLngs)
    } else if (latLngs.length == 1) {
      return latLngs[0].toBounds(1000)
    } else {
      return null
    }
  }

  dispatchEvent(name, detail) {
    const event = new CustomEvent(name, { detail, bubbles: true })

    this.element.dispatchEvent(event)
  }
}
