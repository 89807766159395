import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="menu-icon"
export default class extends Controller {
  static targets = ["bar"]
  static values = {
    active: Boolean
  }

  connect() {
  }

  activeValueChanged() {
  }

}
