const defaultOptions = {
  bubbles: true
}

export function dispatchEvent(element, name, options) {
  options = options || defaultOptions
  const event = new CustomEvent(name, options)

  // console.log("Triggering", name, "from", element, "with", options)

  return element.dispatchEvent(event)
}